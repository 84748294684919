import { Button, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import styles from "./index.module.scss";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DashboardService from "../../services/dashboard";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import Modal from "../../components/Common/Modal";
import Menu from "../AdminForms/Menu";
import Select from "react-select";
import {
  FilterIcon,
  LockClosedIcon,
  SearchIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FilterIcon as FilterIconFilled } from "@heroicons/react/solid";
import {
  filterRowsDataInCols,
  getUniqueTableColValuesMap,
} from "../../common/utils";
import ExcelDownload from "../../components/Common/ExcelDownload";

const multiSelectFilterColsNames = [
  "uid",
  "message_status",
  "message_class",
  "job_type",
];

const debounce = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const Transactions = (props) => {
  const { passFilteredData, gridData: propData, ...rest } = props;
  // console.log("advsearch ==> ", rest, props);
  const ref = useRef();
  const [gridData, setData] = useState([]);
  const [flagsAdvData, setFlagsAdvData] = useState({});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [showFilterModal, setShowFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [showVocadoMessagesModal, setshowVocadoMessagesModal] = useState(null);
  const [colFilter, setColFilter] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [colValsMapData, setColValsMapData] = useState(new Map());
  const formRef = useRef(null);

  const renderSelColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      const uniqVals = colValsMapData.get(uniqId);
      console.log("uniq", colValsMapData, uniqVals);
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              // trigger={<FilterIcon width="16"  fill={props.filters[props.id] ? '#707070' : 'lightgray'} stroke={props.filters[props.id] ? '#707070' : ''} />}
              className={styles.menu}
            >
              <Select
                className={styles.filerInput}
                options={Array.from(uniqVals || new Set()).map((colVal) => ({
                  value: colVal,
                  label: colVal,
                }))}
                isMulti
                isClearable
                onChange={(val) => {
                  if (val.length) {
                    setColFilter((prev) => ({ ...prev, [uniqId]: val }));
                  } else {
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    });
                  }
                }}
                value={colFilter[uniqId]}
              />
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter, colValsMapData]
  );

  const renderColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              className={styles.menu}
            >
              <InputGroup className={styles.filerInput} size="sm">
                <InputGroup.Text id={uniqId}>
                  <SearchIcon width="16" />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby={uniqId}
                  onChange={({ target: { value } }) =>
                    setColFilter((prev) => ({
                      ...prev,
                      [uniqId]: value.trim(),
                    }))
                  }
                  value={colFilter[uniqId]}
                />
                <Button
                  onClick={() =>
                    setColFilter({
                      ...colFilter,
                      [uniqId]: "",
                    })
                  }
                >
                  <XIcon width="16" />
                </Button>
              </InputGroup>
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter]
  );

  useEffect(() => {
    const filterFn = async () => {
      const data = await filterRowsDataInCols(colFilter, gridData);
      setFilteredData(data);
    };
    filterFn();
  }, [gridData, colFilter]);

  async function fetchAdvFlags(id, title, isXML = false) {
    const data = await DashboardService.getVocadoMessageXml(id);
    console.log(data);
    if (data) {
      setshowVocadoMessagesModal({
        title,
        data,
        isXML,
      });
    } else {
      setshowVocadoMessagesModal({
        title,
      });
    }
  }

  const columns: Array<CustomColumn> = [
    {
      name: "uid",
      label: "UID",
      width: 90,
      styles: { maxWidth: "10%" },
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: `text-turncate`,
      showInExcel: true,
      renderHeader: renderColHeader,
      // renderRow: (value, row, index) => {
      //   return row.message ? (
      //    <>
      //     <a
      //       href="#"
      //       onClick={() => {
      //         if (row.message) {
      //           console.log(
      //             // `${value} - ${(row.message as any)?.message_class}`
      //             `${value} - ${row.message_class}`
      //           );
      //           setShowModal({
      //             title: `${value} - ${row.message_class}`,
      //             data: row.message,
      //           });
      //         }
      //       }}
      //       style={{
      //         display: "inline-block",
      //         whiteSpace: "nowrap",
      //         width: "150px",
      //         textOverflow: "ellipsis",
      //         overflow: "hidden",
      //       }}
      //     >
      //       {value}
      //     </a></>
      //   ) : (
      //     <>{value}</>
      //   );
      // },
    },
    {
      name: "message_id",
      label: "Vocado Message ID",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderRow: (value, row, index) => {
        return row.message_id ? (
          <a
            href="#"
            onClick={() => {
              if (row.message_id) {
                fetchAdvFlags(
                  row.message_id,
                  `${row.uid} - ${row.message_class} : ${row.job_id}`
                );
              }
            }}
            // style={{
            //   display: "inline-block",
            //   whiteSpace: "nowrap",
            //   width: "120px",
            //   textOverflow: "ellipsis",
            //   overflow: "hidden",
            // }}
          >
            {value}
          </a>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      name: "message_status",
      label: "Message Status",
      // width: 130,
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderHeader: renderSelColHeader,
      renderRow: (value, row, index) => {
        return row.message_status == "Failed" ? (
          <a
            href="#"
            onClick={() => {
              if (row.message_status == "Failed") {
                console.log(
                  // `${value} - ${(row.message as any)?.message_class}`
                  `${value} - ${row.message_class}`
                );
                setShowModal({
                  title: `Vocado Message Errors`,
                  data: row.message_errors,
                });
              }
            }}
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "red",
              fontWeight: "bold",
            }}
          >
            {value}
          </a>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      name: "message_class",
      label: "Message Class",
      width: 200,
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderHeader: renderSelColHeader,
    },
    {
      name: "job_type",
      label: "Job Type",
      width: 100,
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderHeader: renderSelColHeader,
    },
    {
      name: "created_at",
      label: "Created At",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      sortable: true,
    },
    {
      name: "updated_at",
      label: "Updated At",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      sortable: true,
    },
    {
      name: "job_id",
      label: "Job ID",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderHeader: renderColHeader,
    },
  ];

  useEffect(() => {
    async function fetchStudentTransactions() {
      setIsLoading(true);
      const data = await DashboardService.getTransactions();
      setIsLoading(false);
      setData(
        data
          .map((d) => ({ ...d, created_at: new Date(d.created_at).valueOf() }))
          .sort((a, b) => b.created_at - a.created_at)
          .map((d) => ({
            ...d,
            created_at: new Date(d.created_at).toISOString(),
          }))
      );
    }
    fetchStudentTransactions();
    const data = [];
  }, []);

  useEffect(() => {
    const getColVals = async () => {
      const data = await getUniqueTableColValuesMap(
        gridData,
        multiSelectFilterColsNames
      );
      console.log("data ==> ", data);
      setColValsMapData(data);
    };
    getColVals();
  }, [gridData, multiSelectFilterColsNames]);

  const clearAllFilters = useCallback(() => {
    // setData([]);
    setColFilter({});
    setFilteredData([]);
    // setColValsMapData(new Map());
  }, []);

  return (
    <div className="">
      <div className={styles.message}>
        Displaying results retrieved within the last 24 hrs.
      </div>
      <div className={styles.clear_all}>
        <Button
          className={styles.clear_all_btn}
          size="sm"
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
      </div>
      <div className={styles.download_btn}>
        <ExcelDownload data={gridData} filename={"Transactions"} isVisible />
      </div>

      <div className="d-flex justify-content-center">
        <CustomTable
          parentClass={styles.fullTableWidth as any}
          isLoading={isLoading}
          rows={filteredData}
          ref={ref}
          columns={columns}
          //  headerCellClass={styles.headerCellClass}
          //  bodyCellClass={styles.cellClass}
          bordered
          rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
          style={{ tableLayout: "fixed" }}
          rowClass="test"
          responsive
          striped
          title=""
          hasTableActions
          tableActionProps={{
            hasAddRow: false,
            hasSearch: false,
            hasDownload: false,
          }}
          pagination="default"
          lineHeight={"14px"}
        />
      </div>
      {!!showModal && (
        <Modal
          handleClose={() => setShowModal(false)}
          className={styles.jobs_modal}
          size="lg"
          config={{
            title: showModal.title,
            footer: false,
            body: <div className={styles.Modal_Body}>{showModal.data}</div>,
          }}
        />
      )}
      {!!showVocadoMessagesModal && (
        <Modal
          handleClose={() => setshowVocadoMessagesModal(false)}
          config={{
            title: showVocadoMessagesModal.title,
            footer: false,
            body: (
              <div className="word-break-all">
                {showVocadoMessagesModal.data ? (
                  <pre>{showVocadoMessagesModal.data}</pre>
                ) : (
                  "Message not found"
                )}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};
export default Transactions;
